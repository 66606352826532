import * as React from 'react'
import { IconLinkButton } from '@toasttab/buffet-pui-buttons'
import { ChevronLeftIcon } from '@toasttab/buffet-pui-icons'
import { CardContainer } from '@toasttab/buffet-pui-card'
import cx from 'classnames'
import merge from 'lodash/merge'

//#region Types

type SpacingValues = '2' | '4' | '6' | '8' | '12'

type DeepPartial<TObject extends Record<any, any>> = {
  [TKey in keyof TObject]?: TObject[TKey] extends Record<any, any>
    ? DeepPartial<TObject[TKey]>
    : TObject[TKey]
}
//#endregion

//#region Config
const DEFAULT_PAGE_TEMPLATE_OPTIONS = {
  background: 'bg-gray-0',
  page: {
    container: 'px-2 md:pl-12 md:pr-6' as string,
    spacing: {
      y: 'space-y-6' as `space-y-${SpacingValues}`, //this is neat but useless right now. possibly useful later.
      bottom: 'mb-20'
    },
    debug: 'debug',
    as: 'main' as 'div' | 'main'
  },
  content: {
    debug: 'debug',
    as: 'section' as 'div' | 'section',
    padding: {
      y: 'pb-20' as string
    }
  },
  header: {
    debug: 'debug',
    sticky: true as boolean,
    verticalOffsetValue: '64px',
    as: 'header' as 'div' | 'header'
  }
}
//#endregion

//#region Context

type PageTemplateOptions = typeof DEFAULT_PAGE_TEMPLATE_OPTIONS
type PageTemplateContextOptions = DeepPartial<PageTemplateOptions>

const PageTemplateContext = React.createContext<PageTemplateContextOptions>(
  DEFAULT_PAGE_TEMPLATE_OPTIONS
)

const PageTemplateProvider = ({
  children,
  options = DEFAULT_PAGE_TEMPLATE_OPTIONS
}: React.PropsWithChildren<{
  options?: PageTemplateContextOptions
}>) => (
  <PageTemplateContext.Provider
    value={merge(DEFAULT_PAGE_TEMPLATE_OPTIONS, options)}
  >
    {children}
  </PageTemplateContext.Provider>
)

const usePageTemplateOptions = () =>
  React.useContext(PageTemplateContext) as PageTemplateOptions

//#endregion

//#region Page
type PageProps = React.PropsWithChildren<{}>
const Page = (props: PageProps) => {
  const { page } = usePageTemplateOptions()

  return (
    <page.as className={`${page.spacing.y} ${page.spacing.bottom}`}>
      {props.children}
    </page.as>
  )
}
//#endregion

//#region Content
type ContentProps = {
  children: React.ReactNode
  includePanel?: boolean
}

const Content = (props: ContentProps) => {
  const { content, page } = usePageTemplateOptions()
  const { children, includePanel = true } = props

  return (
    <content.as className={cx(page.container, content.padding.y, page.debug)}>
      {includePanel ? (
        <CardContainer>{children}</CardContainer>
      ) : (
        <>{children}</>
      )}
    </content.as>
  )
}
//#endregion

//#region Header
type HeaderProps = {
  debug?: boolean
  subTitle?: string
  title: string
  back?: string
  primaryAction?: React.ReactNode
  secondaryAction?: React.ReactNode
  tabs?: React.ReactNode
}
const Header = (props: HeaderProps) => {
  const { header, page, background } = usePageTemplateOptions()

  const hasTabs = props.tabs !== undefined
  const isSticky = !!header.sticky

  const headerStyle: React.CSSProperties = isSticky
    ? {
        marginTop: header.verticalOffsetValue,
        top: header.verticalOffsetValue
      }
    : {}

  return (
    <header.as
      className={cx(header.debug, page.container, background, {
        'sticky z-10': isSticky,
        'pb-6': hasTabs,
        'pb-8': !hasTabs
      })}
      style={headerStyle}
    >
      <div
        className={cx(
          'flex flex-row space-x-3 justify-between items-start py-4 ',
          'md:space-x-0 md:items-center md:py-8 border-b border-b-solid'
        )}
      >
        <div
          className={cx(
            'flex flex-row space-x-2 items-end transform transition-all'
          )}
        >
          <If hasValue={props.back}>
            <IconLinkButton icon={<ChevronLeftIcon />} href={props.back} />
          </If>
          <div className='flex flex-col flex-1'>
            <If hasValue={props.subTitle}>
              <h2 className='no-site-style font-normal type-overline text-secondary truncate'>
                {props.subTitle}
              </h2>
            </If>
            <h1
              className='no-site-style font-normal type-headline-2 my-0'
              style={{
                textIndent: '-2px' //neat. but not available with the current version of tailwind
              }}
            >
              {props.title}
            </h1>
          </div>
        </div>
        <div className='hidden md:flex md:justify-end lg:space-x-4 space-x-2'>
          <Optional node={props.secondaryAction} />
          <Optional node={props.primaryAction} />
        </div>
      </div>
      <If hasValue={props.tabs}>{props.tabs}</If>
      <div
        className={cx(
          'border-t fixed left-0 right-0 bottom-0 p-3 z-10 bg-white',
          'md:hidden'
        )}
      >
        <div className='grid grid-cols-2 gap-3 justify-items-stretch'>
          <Optional node={props.secondaryAction} />
          <Optional node={props.primaryAction} />
        </div>
      </div>
    </header.as>
  )
}
//#endregion

//#region Shared
type OptionalProps = { node?: React.ReactNode }

const Optional = ({ node }: OptionalProps) =>
  node === undefined ? null : <>{node}</>

type IfProps = React.PropsWithChildren<{
  test?: boolean
  hasValue?: any
}>

const If = ({ test, hasValue, children }: IfProps) =>
  test || !!hasValue ? <>{children}</> : null
//#endregion

//#region Exports
export {
  PageTemplateProvider,
  PageTemplateContextOptions,
  usePageTemplateOptions,
  Page,
  Content,
  Header,
  HeaderProps
}
//#endregion
