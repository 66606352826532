import * as React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import { client, rqClient } from '@local/api/client'
import { EntryRoute } from './routes'
import { QueryClientProvider } from '@tanstack/react-query'
import { PortalProvider } from '@toasttab/ec-layout'
import { useFeature } from '@toasttab/ec-features'

const FLAG = 'ec-platform-use-new-company-setup'

//this is the whole app. the routes do 99% of the work
const App = () => useRoutes([EntryRoute])

/**
 * This is the full application
 */
const AppBootstrap = () => {
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={rqClient}>
        <BrowserRouter>
          <PortalProvider value='data-ec-settings-nav'>
            <React.Suspense fallback={null}>
              <WithFeatureFlag fallback={null}>
                <App />
              </WithFeatureFlag>
            </React.Suspense>
          </PortalProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ApolloProvider>
  )
}

function WithFeatureFlag({
  children,
  fallback
}: React.PropsWithChildren<{ fallback: React.ReactNode }>) {
  const enabled = useFeature(FLAG)

  if (!enabled) {
    console.info(
      `feature flag ${FLAG} is not enabled, ec-settings-navigation will not render anything`
    )
    return <>{fallback}</>
  } else {
    return <>{children}</>
  }
}

export { App, AppBootstrap }
