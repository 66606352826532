import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { AppBootstrap } from './app/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-ec-settings-nav',
  rootComponent: AppBootstrap,
  domElementGetter: () => document.getElementById('page-sidebar')!,
  portalContainers: ['banquetPortalsContainer']
  // sentry: {
  //   publicKey: '',
  //   projectId: '',
  //   releaseVersion: process.env.PKG_VERSION
  // }
})
export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-settings-navigation-spa'
